import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Inicio | PristineOffice Cleaning
			</title>
			<meta name={"description"} content={"La elección prístina para su espacio de trabajo"} />
			<meta property={"og:title"} content={"Inicio | PristineOffice Cleaning"} />
			<meta property={"og:description"} content={"La elección prístina para su espacio de trabajo"} />
			<meta property={"og:image"} content={"https://serenitrefuge.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://serenitrefuge.com/img/190401.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://serenitrefuge.com/img/190401.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://serenitrefuge.com/img/190401.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://serenitrefuge.com/img/190401.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://serenitrefuge.com/img/190401.png"} />
			<meta name={"msapplication-TileImage"} content={"https://serenitrefuge.com/img/190401.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://serenitrefuge.com/img/1.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text as="h1" margin="0px 0px 20px 0px" font="normal 900 52px/1.2 --fontFamily-mono">
						PristineOffice Cleaning
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 16px/1.5 --fontFamily-sansVerdana">
						Bienvenido a PristineOffice Cleaning Services, donde creemos que un espacio de trabajo limpio es la base de la productividad y el éxito. Nuestro dedicado equipo de profesionales de la limpieza está aquí para garantizar que el entorno de su oficina no sólo esté impecable, sino que también sea acogedor y propicio para el trabajo. Nos enorgullecemos de ofrecer un servicio excepcional con una sonrisa, haciendo que su lugar de trabajo brille cada día.
					</Text>
					<Button
						background="--color-primary"
						color="--light"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						padding="12px 30px 12px 30px"
						font="normal 300 16px/1.5 --fontFamily-mono"
						type="link"
						href="/contacts"
						text-decoration-line="initial"
					>
						Contáctenos
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 120px 0" quarkly-title="Partners-2">
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 56px/1.2 --fontFamily-sans" color="--primary">
				Nuestros servicios
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
				color="#606469"
				sm-margin="0px 0px 50px 0px"
			>
				Ofrecemos una amplia gama de servicios de limpieza de oficinas diseñados para satisfacer las diversas necesidades de nuestros clientes. Tanto si necesita una limpieza diaria, una limpieza semanal a fondo o servicios especializados como la limpieza de alfombras y tapicerías, PristineOffice tiene todo lo que necesita. Nuestros planes de servicio flexibles se pueden personalizar para adaptarse a su horario y presupuesto, garantizando que su oficina permanezca impecable sin interrumpir su flujo de trabajo.
			</Text>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box width="100%" margin="3vw 0px 0px 0px">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--primary"
					letter-spacing="-.05em"
					lg-font="normal 400 70px/1.1em &quot;Inter&quot;, sans-serif"
					sm-font="normal 400 40px/1.1em &quot;Inter&quot;, sans-serif"
				>
					¿Por qué elegir PristineOffice?
				</Text>
			</Box>
			<Box
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			/>
			<Box
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
				Atención al detalle
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--dark">
				Prestamos atención a los pequeños detalles que marcan una gran diferencia. Desde ventanas impecables hasta puestos de trabajo desinfectados, no pasamos por alto ningún detalle.
				</Text>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
				Soluciones ecológicas
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--dark">
				Nuestro compromiso con el medio ambiente significa que sólo utilizamos los mejores productos de limpieza ecológicos que son seguros para su personal y el planeta.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
				Personal con experiencia
				</Text>
				<Text font="--base" color="--dark" margin="40px 0px 0px 0px">
				Nuestro equipo de profesionales capacitados aporta una gran experiencia a cada tarea de limpieza, lo que garantiza resultados superiores en todo momento.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="56px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text color="--dark" margin="0px 0px 0px 0px" font="--headline3">
				Satisfacción del cliente
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--dark">
				Su satisfacción es nuestra prioridad. Trabajamos en estrecha colaboración con nuestros clientes para garantizar que se satisfagan sus necesidades específicas y se superen sus expectativas.
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});